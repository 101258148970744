export default {
	cinema: {
		address: 'ул. Ленина, д. 54 А',
		place: null,
		phoneNumbers: ['8 (42436) 6-04-22', '8-962-154-12-08'],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.nevelsky',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBuzzGQs3C',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
